<template>
  <ms-drawer title="Card Data" class="gray" @close="close()">
    <div class="card-data-container" v-if="account && account.cards.length > 0">
      <div class="card-number">
      {{ account.cards[account.cards.length-1].cardLast4.substr(0,4) }}&nbsp;&nbsp;&nbsp;{{ account.cards[account.cards.length-1].cardLast4.substr(4,4) }}&nbsp;&nbsp;&nbsp;{{ account.cards[account.cards.length-1].cardLast4.substr(8,4) }}&nbsp;&nbsp;&nbsp;{{ account.cards[account.cards.length-1].cardLast4.substr(12, 4) }}
      </div>
      <div class="card-exp-m">
      {{ account.cards[account.cards.length-1].expiryMonth }}/{{ account.cards[account.cards.length-1].expiryYear.substr(-2) }}
      </div>
      <div class="card-cvv">
      {{ account.cards[account.cards.length-1].cvv }}
      </div>
      <div class="cardholder">
      {{ this.registration.data.firstName }} {{ this.registration.data.lastName }}
      </div>
    </div>
    <div class="buttons text-center">
      <button class="second" @click="close()">Close</button>
    </div>
    <div
      v-if="loading"
      v-loading="loading"
      class="page-loader" />

    <template slot="footer">
      <el-button class="w-full" round @click="close()">
        Close
      </el-button>
    </template>
  </ms-drawer>
</template>

<script>

export default {
  props: {
    account: {
      type: Object,
      required: true
    },

    pinData: {
      type: Object,
      required: true
    }
  },

  components: {
  },

  data () {
    return {
      open: true,
      loading: false,
      submitError: null,
      fieldStyle: {
        'font-size': '15px',
        color: 'black',
        'font-family': '"Lato", "Arial", "Helvetica", sans-serif'
      },
      fieldStyleNum: {
        'font-size': '23px',
        'font-weight': 'normal',
        color: 'black',
        'font-family': '"Lato", "Arial", "Helvetica", sans-serif'
      },
      shownCount: 0
    }
  },

  computed: {
    currentUser () {
      return this.$store.state.app.user
    },

    registration () {
      return this.currentUser?.registration || null
    },

    isBusiness () {
      return this.account.regType === 'Business'
    },
    cardholderName () {
      if (!this.account || !this.account.cards || !this.account.cards.length) { return '' }
      const card = this.account.cards[this.account.cards.length - 1]
      if (card && !card.embossingPerson) {
        try {
          const cardholder = JSON.parse(card.cardholder)
          return cardholder.name
        } catch (e) {
        }
      }
      return (card && card.embossingPerson) || ''
    },

    cardholderBusiness () {
      if (!this.account || !this.account.cards || !this.account.cards.length) return ''
      const card = this.account.cards[this.account.cards.length - 1]
      return (card && card.embossingBusiness) || ''
    },

    isCustomer () {
      return this.currentUser.isCustomer
    }
  },

  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
