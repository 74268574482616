<template>
  <div>
    <div>
    <panel title="Account Balances">
      <div class="table-wrapper">
        <div class="flex-between">
          <h2>&nbsp;</h2>
          <FundsSvg fill="#99AABB" />
        </div>
        <data-list :list="fundsData" class="mt-15" />
      </div>

      <div
        v-for="assetPanel in assetsData"
        :key="assetPanel.title"
        class="table-wrapper mt-20">
        <div class="flex-between">
          <h2>ASSET - {{ assetPanel.title }}</h2>
          <DigitalAssetsSvg />
        </div>

        <data-list :list="assetPanel.items" class="mt-15 mb-10" />

        <data-list-item :item="{ label: 'Wallet Address', text: getIncomingAssetWalletAddress(assetPanel.title) || null }">
          <el-button
            v-if="!getIncomingAssetWalletAddress(assetPanel.title)"
            type="success"
            :disabled="!hasAssets"
            size="small"
            round
            @click="wallet(assetPanel.title, account.id)">
            Activate
          </el-button>
        </data-list-item>

        <el-button
          v-if="systemMaintenanceStatus && getIncomingAssetWalletAddress(assetPanel.title)"
          type="success"
          size="small"
          round
          @click="getWallet(item, account.id)">
          Update
        </el-button>
      </div>
    </panel>
  </div>
  </div>

</template>

<script>
import FundsSvg from '@/assets/images/icons/funds.svg'
import DataListItem from '@/components/common/ui/DataListItem'
import DigitalAssetsSvg from '@/assets/images/icons/digital-asset.svg'

export default {
  components: {
    FundsSvg,
    DataListItem,
    DigitalAssetsSvg
  },

  props: {
    account: {
      type: Object,
      required: true
    }
  },

  computed: {
    user () {
      return this.$store.state.app.user
    },

    registration () {
      return this.user?.registration || null
    },

    systemMaintenanceStatus () {
      return this.$store.state.app.maintenance
    },

    provider () {
      return this.account.serviceProviders
    },

    systemFeatures () {
      return this.$store.state.app.systemFeatures
    },

    featureStatus () {
      return this.systemFeatures['Digital Assets']
    },

    currencies () {
      return this.$store.state.app.currencies.map(currency => ({
        id: currency.id,
        text: currency.currencyNickname
      }))
    },

    assetsList () {
      return this.$store.state.app.currencies
        .filter(currency => currency.systemCurrencyType === 'Assets')
        .map(currency => currency.currencyNickname)
    },

    fundsData () {
      return [
        { label: 'Currency', text: this.account.currency },
        // { label: 'Name', text: this.account.currencyName },
        { label: 'Available Balance', text: `$ ${this.account.systemAvailableBalance}` },
        { label: 'Balance', text: `$ ${this.account.systemTotalBalance}` }
      ]
    },

    assetsData () {
      if (!this.provider?.assets || !this.featureStatus) {
        return []
      }

      return this.assetsList.map(asset => ({
        title: asset,
        items: [
          {
            label: 'Currency',
            text: asset
          },
          {
            label: 'Name',
            text: this.getCurrencyName(asset)
          },
          {
            label: 'Available Balance',
            text: this.getAssetsSystemAssetAvailableBalance(asset)
              ? parseFloat(this.getAssetsSystemAssetAvailableBalance(asset))
              : ''
          },
          {
            label: 'Balance',
            text: this.getAssetsSystemAssetTotalBalance(asset)
              ? parseFloat(this.getAssetsSystemAssetTotalBalance(asset))
              : ''
          },
          {
            label: 'Tag Required',
            text: this.getTagRequired(asset)
          },
          {
            label: 'Tag',
            text: this.getTag(asset)
          }
        ]
      }))
    },

    hasAssets () {
      return !!this.registration?.profileSettings?.assets
    }
  },

  methods: {
    getCurrencyName (id) {
      const item = this.currencies.find(c => parseInt(c.id) === parseInt(id))
      return item?.text || 'N/A'
    },

    getTagRequired (currencyNickname) {
      const currency = this.$store.state.app.currencies.find(currency => currency.currencyNickname === currencyNickname)

      return currency?.requiresTag ? 'Yes' : 'No'
    },

    getAssetsSystemAssetAvailableBalance (currencyNickname) {
      const wallet = (this.account.assetsWallets || []).find(wallet => wallet.currency.currencyNickname === currencyNickname)
      return wallet?.systemAssetAvailableBalance || null
    },

    getAssetsSystemAssetTotalBalance (currencyNickname) {
      const wallet = (this.account.assetsWallets || []).find(wallet => wallet.currency.currencyNickname === currencyNickname)
      return wallet?.systemAssetTotalBalance || null
    },

    getIncomingAssetWalletAddress (currencyNickname) {
      const wallet = (this.account.assetsWallets || []).find(wallet => wallet.currency.currencyNickname === currencyNickname)
      return wallet?.incomingAssetWalletAddress || null
    },

    getTag (currencyNickname) {
      const wallet = (this.account.assetsWallets || []).find(wallet => wallet.currency.currencyNickname === currencyNickname)
      return wallet?.tag || null
    },

    async wallet (currencyNickname, id) {
      const loader = this.$loading()
      try {
        const response = (await this.$store.dispatch('account/action', {
          id: id,
          action: 'Wallet',
          payload: {
            currencyNickname,
            registrationId: this.registration.id
          }
        }))
        if (response?.action?.error) {
          this.$notify.error('Error Received - Wallet Address not Set. Please check information and try again - ' + response.action.errors)
        }
        await this.$store.dispatch('app/loadData', true)
      } catch (e) {
        this.$notify.error(window.errorMessage)
        console.error(e)
      } finally {
        loader.close()
      }
    },

    async getWallet (currencyNickname, id) {
      const loader = this.$loading()
      try {
        const response = (await this.$store.dispatch('accounts/action', {
          id: id,
          action: 'GetWallet',
          payload: {
            currencyNickname: currencyNickname,
            registrationId: this.registration.id
          }
        }))
        if (response.action.error) {
          this.$notify.error('Error Received - Wallet Address not Set. Please check information and try again - ' + response.action.errors)
        }
        await this.$store.dispatch('app/loadData', true)
      } catch (e) {
        this.$notify.error(window.errorMessage)
      } finally {
        loader.close()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .table-wrapper {
    background-color: $bg;
    border-radius: 12px;
    padding: 8px 15px 15px 15px;
  }
</style>
