<template>
  <ms-drawer :visible="true" title="Card Data" class="gray" @close="close()">
    <vgv-form ref="vgvForm" :vaultId="pinData.vaultId" :environment="pinData.environment" :cardToken="pinData.pinToken" :cardId="pinData.id">
      <div class="form-row form-fullw">
        <vgv-field label="Last 4 digits" fieldName="last4" :css="fieldStyle" />
      </div>
      <div class="form-row form-split mt-15">
        <vgv-field class="field" label="Expiry month" fieldName="expiryMonth" :css="fieldStyle" />
        <vgv-field class="field" label="Expiry year" fieldName="expiryYear" :css="fieldStyle" />
      </div>
      <div class="form-row form-fullw mt-15">
        <vgv-field label="New pin" fieldName="pin" :css="fieldStyle" />
      </div>
    </vgv-form>

    <div v-if="submitError" class="error-box">
      <label class="error-text">{{ submitError }}</label>
    </div>

    <template slot="footer">
      <div class="flex-buttons">
        <el-button class="max-w-1-3" round @click="close()">
          Close
        </el-button>
        <el-button
          type="success"
          class="gradient"
          :loading="loading"
          @click="setPin()"
          round>
          Submit
        </el-button>
      </div>
    </template>
  </ms-drawer>
</template>

<script>
import VgvForm from './VgvForm'
import VgvField from './VgvField'

export default {
  props: {
    account: {
      type: Object,
      required: true
    },

    pinData: {
      type: Object,
      required: true
    }
  },

  components: {
    VgvForm,
    VgvField
  },

  data () {
    return {
      submitError: null,
      loading: false,
      fieldStyle: {
        'font-size': '15px',
        color: '#41566c'
      }
    }
  },

  computed: {

  },

  methods: {
    async setPin () {
      this.loading = true
      const result = await this.$refs.vgvForm.submit()
      this.loading = false
      if (result.message) {
        this.submitError = result.sysMessage || result.message
        if (!result.clientError) this.$emit('refresh')
      } else {
        this.$notify.success('Card pin successfully updated.')
        this.close()
      }
    },

    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.error-box {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: -40px;
  margin-bottom: 20px;
}

.form-split {
  display: flex;
  .field + .field {
    margin-left: 15px;
  }
}
</style>
