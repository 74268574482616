<template>
  <div>
    <panel title="Account Details">
      <data-list :list="items" class="mb-10" />

      <data-list-item :item="{ label: 'Account Key', text: account.accountKey || null }" />
      <el-button
        v-if="!account.accountKey"
        type="primary"
        size="small"
        round
        @click="accountKey()">
        Account Key
      </el-button>

      <data-list :list="items2" class="mb-10 mt-10" />

      <data-list-item :item="{ label: 'Fees' }" />
      <el-button
        type="primary"
        size="small"
        class="mt-5"
        round
        @click="showFeeModal = true">
        View
      </el-button>
    </panel>

    <ms-drawer v-if="showFeeModal" title="Fee Schedule" class="gray" @close="showFeeModal = false">
      <data-list :list="feesDataList" />

      <empty v-if="!feesDataList.length">No fee schedule data is set.</empty>

      <template slot="footer">
        <el-button class="w-full" round @click="showFeeModal = false">
          Close
        </el-button>
      </template>
    </ms-drawer>
  </div>
</template>

<script>
import DataListItem from '@/components/common/ui/DataListItem'

export default {
  components: {
    DataListItem
  },

  props: {
    account: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      showFeeModal: false
    }
  },

  computed: {
    user () {
      return this.$store.state.app.user
    },

    registration () {
      return this.user?.registration || null
    },

    accounts () {
      return this.registration?.accounts || []
    },

    feeSchedule () {
      return this.account.feeSchedule
    },

    provider () {
      return this.account.serviceProviders
    },

    feesDataList () {
      if (!this.feeSchedule) return []
      const feeScheduleItems = []
      console.log(this.provider)

      feeScheduleItems.push({
        subtitle: 'Volume Fee',
        spacer: true
      })

      feeScheduleItems.push({
        label: 'Fee',
        text: this.$percentage(this.$formattedTwoDecimal(this.feeSchedule?.volumeFee.fee))
      })

      feeScheduleItems.push({
        subtitle: 'Monthly Access Fee',
        spacer: true
      })

      feeScheduleItems.push({
        label: 'Fee',
        text: this.$money(this.$formattedTwoDecimal(this.feeSchedule?.monthlyAccessFee.fee))
      })

      feeScheduleItems.push({
        subtitle: 'Dormant Accounts',
        spacer: true
      })

      feeScheduleItems.push({
        label: 'Fee',
        text: this.$money(this.$formattedTwoDecimal(this.feeSchedule?.dormantAccounts.fee))
      })

      if (this.provider.ahc) {
        feeScheduleItems.push({
          subtitle: 'ACH',
          spacer: true
        })

        feeScheduleItems.push({
          label: 'Deposit',
          text: this.$money(this.$formattedTwoDecimal(this.feeSchedule?.ach.per_deposit))
        })

        feeScheduleItems.push({
          label: 'Withdrawal',
          text: this.$money(this.$formattedTwoDecimal(this.feeSchedule?.ach.per_withdrawal))
        })
      }

      if (this.provider.acct) {
        feeScheduleItems.push({
          subtitle: 'Account Transfer',
          spacer: true
        })

        feeScheduleItems.push({
          label: 'Withdrawal',
          text: this.$money(this.$formattedTwoDecimal(this.feeSchedule?.accountTransfer.per_withdrawal))
        })
      }

      if (this.provider.checks) {
        feeScheduleItems.push({
          subtitle: 'Checks',
          spacer: true
        })

        feeScheduleItems.push({
          label: 'Deposit',
          text: this.$money(this.$formattedTwoDecimal(this.feeSchedule?.checks.per_deposit))
        })

        feeScheduleItems.push({
          label: 'Withdrawal',
          text: this.$money(this.$formattedTwoDecimal(this.feeSchedule?.checks.per_withdrawal))
        })
      }

      if (this.provider.domWires) {
        feeScheduleItems.push({
          subtitle: 'Domestic Wire',
          spacer: true
        })

        feeScheduleItems.push({
          label: 'Deposit',
          text: this.$money(this.$formattedTwoDecimal(this.feeSchedule?.domesticWire.per_deposit))
        })

        feeScheduleItems.push({
          label: 'Withdrawal',
          text: this.$money(this.$formattedTwoDecimal(this.feeSchedule?.domesticWire.per_withdrawal))
        })
      }

      if (this.provider.intlWires) {
        feeScheduleItems.push({
          subtitle: 'International Wire',
          spacer: true
        })

        feeScheduleItems.push({
          label: 'Deposit',
          text: this.$money(this.$formattedTwoDecimal(this.feeSchedule?.internationalWire.per_deposit))
        })

        feeScheduleItems.push({
          label: 'Withdrawal',
          text: this.$money(this.$formattedTwoDecimal(this.feeSchedule?.internationalWire.per_withdrawal))
        })
      }

      if (this.provider.exchange) {
        feeScheduleItems.push({
          subtitle: 'Exchange',
          spacer: true
        })

        feeScheduleItems.push({
          label: 'Fee',
          text: this.$money(this.$formattedTwoDecimal(this.feeSchedule?.exchange.fee))
        })
      }

      if (this.provider.assets) {
        feeScheduleItems.push({
          subtitle: 'Assets',
          spacer: true
        })

        feeScheduleItems.push({
          label: 'Fee',
          text: this.$money(this.$formattedTwoDecimal(this.feeSchedule?.assets.fee))
        })

        feeScheduleItems.push({
          label: 'Deposit',
          text: this.$money(this.$formattedTwoDecimal(this.feeSchedule?.assets.per_deposit))
        })

        feeScheduleItems.push({
          label: 'Withdrawal',
          text: this.$money(this.$formattedTwoDecimal(this.feeSchedule?.assets.per_withdrawal))
        })
      }

      if (this.provider.debitCards) {
        feeScheduleItems.push({
          subtitle: 'Debit Card',
          spacer: true
        })

        feeScheduleItems.push({
          label: 'Fee',
          text: this.$money(this.$formattedTwoDecimal(this.feeSchedule?.debitCard.fee))
        })

        feeScheduleItems.push({
          label: 'Withdrawal',
          text: this.$money(this.$formattedTwoDecimal(this.feeSchedule?.debitCard.per_withdrawal))
        })
      }

      return feeScheduleItems
    },

    items () {
      return [
        {
          label: 'Account Number',
          text: this.account.accountNumber
        },
        {
          label: 'Account Label',
          text: this.account.label
        }
      ]
    },

    items2 () {
      return [
        {
          label: 'Status',
          text: this.account.accountStatus
        },
        {
          label: 'Reference Code',
          text: this.account.primeAccount.QPaymentReference,
          condition: ['Prime Trust Test', 'Prime Trust'].includes(this.account.serviceProviders?.provider_name)
        },
        {
          label: 'Routing Number',
          text: this.account.routingNumber,
          condition: ['Solid', 'Solid Test'].includes(this.account.serviceProviders?.provider_name)
        },
        {
          label: 'Plan',
          text: this.account.regType
        }
      ]
    }
  },

  methods: {
    async accountKey () {
      const loading = this.$loading()
      try {
        const response = (await this.$store.dispatch('account/action', {
          id: this.account.id,
          action: 'AccountKey'
        }))
        console.log(response)
        if (response.action?.error) {
          this.$notify.error(window.errorMessage)
          return
        }
        await this.$store.dispatch('app/loadData', true)
      } catch (e) {
        this.$notify.error(window.errorMessage)
      } finally {
        loading.close()
      }
    }
  }
}
</script>
