<template>
  <div>
    <panel v-if="card" title="Card Details" collapable>
      <data-list :list="mainList" class="mb-10" />

      <data-list-item :item="{ label: 'Settings' }">
        <div class="flex">
          <el-button
            type="primary"
            size="small"
            round
            @click="showSettings = true">
            Settings
          </el-button>
        </div>
      </data-list-item>

      <template v-if="card.cardStatus && cardServicesFeatureEnabled">
        <data-list-item :item="{ label: 'Options' }" />
        <div class="flex">
          <el-button
            v-if="card.cardStatus === 'Active'"
            type="primary"
            size="small"
            class="mt-5"
            round
            @click="suspend()">
            Suspend
          </el-button>
          <el-button
            v-if="card.cardStatus === 'Suspended'"
            type="primary"
            size="small"
            class="mt-5"
            round
            @click="suspend()">
            UnSuspend
          </el-button>
          <el-button
            v-if="card.cardStatus === 'Active' || card.cardStatus === 'Suspended'"
            type="primary"
            size="small"
            class="mt-5"
            round
            @click="loadCardModal()">
            View Card
          </el-button>
        </div>
      </template>
    </panel>

    <card-settings v-if="showSettings" :card="card" @close="showSettings = false" />
    <card-preview v-if="showCard" :account="account" :pinData="showCard" @close="showCard = false" />
    <card-pin v-if="showUpdatePin" :account="account" :pinData="showUpdatePin" @refresh="reloadUpdatePin()"  @close="showUpdatePin = false" />
  </div>
</template>

<script>
import CardPin from './card/CardPin'
import CardPreview from './card/CardPreview'
import CardSettings from './card/CardSettings'
import DataListItem from '@/components/common/ui/DataListItem'

export default {
  props: {
    account: {
      type: Object,
      required: true
    }
  },

  components: {
    CardPin,
    DataListItem,
    CardPreview,
    CardSettings
  },

  data () {
    return {
      showSettings: false,
      showDelivery: false,
      showActivation: false,
      showCard: false,
      showUpdatePin: false
    }
  },

  computed: {
    systemFeatures () {
      return this.$store.state.app.systemFeatures
    },

    cardServicesFeatureEnabled () {
      return this.systemFeatures['Card Services']
    },

    card () {
      const cardIdx = this.account.cards?.length - 1
      if (this.account.cards.length <= 0 || !this.account.cards[cardIdx].cardStatus) return null
      return this.account.cards[cardIdx]
    },

    mainList () {
      return [
        {
          label: 'Card Number',
          text: `${this.card.cardLast4.substr(0, 6)}******${this.card.cardLast4.substr(-4)}`
        },
        {
          label: 'Expiry',
          text: `${this.card.expiryMonth} / ${this.card.expiryYear}`
        },
        {
          label: 'Card Type',
          text: `${this.card.cardType.charAt(0).toUpperCase()}${this.card.cardType.slice(1)}`
        },
        {
          label: 'Label',
          text: this.card.label
        },
        {
          label: 'Status',
          text: this.getStatus(this.card.cardStatus)
        }
      ]
    }
  },

  methods: {
    async loadPinModal () {
      const response = await this.loadCard()
      if (response.success !== false) {
        this.showUpdatePin = {
          ...response.action,
          id: response.item?.cardId
        }
      } else {
        this.$notify.error(window.errorMessage)
      }
    },

    async loadCardModal () {
      const response = await this.loadCard()
      if (response.success !== false) {
        this.showCard = {
          ...response.action,
          id: response.item?.cardId
        }
      } else {
        this.$notify.error(window.errorMessage)
      }
    },

    reloadUpdatePin () {
      const oldPin = { ...this.showUpdatePin }
      this.showUpdatePin = null
      this.$nextTick(() => {
        this.showUpdatePin = oldPin
      })
    },

    loadCard () {
      return this.$store.dispatch('cards/action', {
        id: this.card.id,
        action: 'pinToken'
      })
    },

    async suspend () {
      var response = await this.$store.dispatch('cards/action', {
        id: this.account.cards[this.account.cards.length - 1].id,
        action: 'SuspendCard'
      })
      if (response.action.error) {
        this.$notify.error('Error: ' + response.action.errorMsg)
      } else {
        await this.$store.dispatch('app/loadData', true)
      }
    },

    getStatus (status) {
      return window.cardStatuses[status] || status
    }
  }
}
</script>
