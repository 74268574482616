<template>
  <ms-drawer title="Settings" class="gray" @close="close()">
    <data-list :list="settingsData" />

    <template slot="footer">
      <el-button class="w-full" round @click="close()">
        Close
      </el-button>
    </template>
  </ms-drawer>
</template>

<script>
export default {
  props: {
    card: {
      type: Object,
      default: null
    }
  },

  computed: {
    settingsData () {
      return [
        {
          label: 'ATM Access',
          text: this.card.atmAccess ? 'Yes' : 'No'
        },
        {
          label: 'Limit Amount',
          text: [0, null].includes(this.card.limitAmount) ? 'None' : this.card.limitAmount
        },
        {
          label: 'Limit Interval',
          text: this.card.limitInterval ? `${this.card.limitInterval.charAt(0).toUpperCase()}${this.card.limitInterval.slice(1)}` : 'None'
        },
        {
          label: 'Allowed Categories',
          text: this.card.allowedCategories
        },
        {
          label: 'Blocked Categories',
          text: this.card.blockedCategories
        },
        {
          label: 'Allowed Merchants',
          text: this.card.allowedMerchants
        },
        {
          label: 'Blocked Merchants',
          text: this.card.blockedMerchants
        }
      ]
    }
  },

  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
