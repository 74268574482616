<template>
<div class="content-form vgv-form">
  <div v-if="!vgvScriptLoaded">
    Loading, please wait...
  </div>
  <slot v-if="vgvScriptLoaded" />
</div>
</template>
<script>
export default {
  props: [
    'vaultId', 'environment', 'cardToken', 'cardId'
  ],
  data: function () {
    return {
      vgvScriptLoaded: false
    }
  },
  methods: {

    addVgsCollectScript () {
      var vgvScript = document.createElement('script')
      vgvScript.src = 'https://js.verygoodvault.com/vgs-collect/2.11.0/vgs-collect.js'
      vgvScript.type = 'text/javascript'
      vgvScript.onload = () => { this.onVgvLoaded() }
      document.head.append(vgvScript)
    },
    onVgvLoaded () {
      this.collectForm = window.VGSCollect.create(this.vaultId, this.environment, (state) => {})
      this.vgvScriptLoaded = true
    },
    submit () {
      return new Promise((resolve) => {
        this.collectForm.submit('/v1/card/' + this.cardId + '/pin', {
          method: 'POST',
          headers: { 'sd-pin-token': this.cardToken },
          data: (formValues) => {
            return {
              last4: formValues.last4,
              pin: formValues.pin,
              expiryMonth: formValues.expiryMonth,
              expiryYear: formValues.expiryYear
            }
          }
        }, function (status, data) {
          resolve(data)
        }, function (status) {
          resolve({ message: 'Please fill in all fields', clientError: true })
        })
      })
    }

  },
  created () {
    this.addVgsCollectScript()
  }
}
</script>
