<template>
  <pull-to :top-load-method="load">
    <ms-select
      v-model="account"
      label="Select Account"
      :options="accountOptions"
    />

    <template v-if="selectedAccount">
      <account-details :account="selectedAccount" class="mt-20" />
      <card-details v-if="selectedAccount.profileSettings.debitCards" :account="selectedAccount" class="mt-20" />
      <account-settings v-if="false" :account="selectedAccount" class="mt-20" />
      <account-balances :account="selectedAccount" class="mt-20" />
    </template>

    <template slot="top-block">
      <div>
        <div
          v-if="reloading"
          v-loading="reloading"
          class="basic-loader mb-20" />
      </div>
    </template>
  </pull-to>
</template>

<script>
import navigation from './navigation'

import PullTo from 'vue-pull-to'
import CardDetails from './my-accounts/CardDetails'
import AccountDetails from './my-accounts/AccountDetails'
import AccountBalances from './my-accounts/AccountBalances'
import AccountSettings from './my-accounts/AccountSettings'

export default {
  components: {
    PullTo,
    CardDetails,
    AccountDetails,
    AccountSettings,
    AccountBalances
  },

  data () {
    return {
      account: null,
      reloading: false
    }
  },

  computed: {
    user () {
      return this.$store.state.app.user
    },

    registration () {
      return this.user?.registration || null
    },

    accounts () {
      return this.registration?.accounts || []
    },

    accountOptions () {
      return this.accounts.map(account => ({
        id: account.id,
        text: account.accountNumber
      }))
    },

    selectedAccount () {
      return this.account ? this.accounts.find(acc => acc.id === this.account) : null
    }
  },

  created () {
    this.$store.commit('page/setPageInfo', {
      title: 'My Accounts'
    })

    this.$store.commit('page/setTabs', navigation)
  },

  methods: {
    async load (loaded) {
      this.reloading = true
      try {
        await this.$store.dispatch('app/loadData', true)
      } catch (e) {
        console.error(e)
      } finally {
        loaded('done')
        this.reloading = false
      }
    }
  }
}
</script>
