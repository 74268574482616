<template>
  <panel title="Account Settings" collapable>
    ...
  </panel>
</template>

<script>
export default {
  props: {
    account: {
      type: Object,
      required: true
    }
  }
}
</script>
